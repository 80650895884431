<template>
  <div>
    <v-row dense>
      <v-col>
        <div>
          <b>Período letivo {{ proc_seletivo.proc_descricao }}</b>
        </div>
        <div>
          <b>Turma de destino {{ disciplinas[0].t_descricao }} - Carga horária: {{ disciplinas[0].chtotal }}</b>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-badge :content="disciplinas.length + ' Disciplinas'" tile></v-badge>
        <DataTable
          style="background-color: #42A5F5; font-size: 10.5px; border: 1px solid #ccc"
          :value="disciplinas"
          class="p-datatable-gridlines"
          selectionMode="single"
          :selection.sync="itemSelecionado"
          :scrollable="true"
          scrollHeight="200px"
        >
          <Column header="" headerStyle="width: 35px; background-color: #eeeeee" bodyStyle="width: 35px">
            <template #body="item">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="itemSelecionado = item.data" dark icon v-bind="attrs" v-on="on">
                    <v-icon color="black">mdi mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item @click="matricula(item)">
                    <v-list-item-icon>
                      <v-icon>mdi mdi-refresh</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Excluir</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </Column>

          <column header="Disciplinas" field="disciplina" headerStyle="background-color: #eeeeee; width: 180px" bodyStyle="width: 180px"> </column>
          <column header="Turma" field="t_descricao" headerStyle="background-color: #eeeeee; width: 95px" bodyStyle="width: 25px"> </column>
          <column header="Périodo" field="dspa_descricao" headerStyle="background-color: #eeeeee; width: 95px" bodyStyle="width: 25px"> </column>

          <column header="Carga horária" field="ch" headerStyle="background-color: #eeeeee; width: 65px" bodyStyle="width: 25px"> </column>
        </DataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";

export default {
  name: "pre-matricula-academico",
  props: ["aluno", "ps_id"],
  data() {
    return {
      proc_seletivo: [],
      disciplinas: [],
      itemSelecionado: "",
      m_id: this.$store.state.contexto.contrato.m_id,
    };
  },
  created() {
    http.send("", { proc: `[fx jaguar pre-matricula] 'findps',${this.m_id}` }).then((res) => {
      this.proc_seletivo = res.dados.data[0];
      http.send("", { proc: `[fx jaguar pre-matricula] 'listadisciplinas',${this.m_id},${this.proc_seletivo.ps_id}` }).then((res) => {
        console.log(res.dados.data);
        this.disciplinas = res.dados.data;
      });
    });
  },
  methods: {},
};
</script>

<style></style>
