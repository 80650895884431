const cadbib_tipo_usuario = () => import("@/views/modulos/biblioteca/cadbib_tipo_usuario.vue");
const cadlivros_tipoobra = () => import("@/views/modulos/biblioteca/cadlivros_tipoobra.vue");
const cadacervo = () => import("@/views/modulos/biblioteca/cadacervo.vue");
const cadautores = () => import("@/views/modulos/biblioteca/cadautores.vue");
const cadeditoras = () => import("@/views/modulos/biblioteca/cadeditoras.vue");
const cadassuntos = () => import("@/views/modulos/biblioteca/cadassuntos.vue");
const cadgeneros = () => import("@/views/modulos/biblioteca/cadgeneros.vue");
const bibiventario = () => import("@/views/modulos/biblioteca/bibiventario.vue");
const cadetiquetasavulsas = () => import("@/views/modulos/biblioteca/cadetiquetasavulsas.vue");
const cadbib_usuarios = () => import("@/views/modulos/biblioteca/cadbib_usuarios.vue");
const conslistaespera = () => import("@/views/modulos/biblioteca/conslistaespera.vue");
const cadbiblink = () => import("@/views/modulos/biblioteca/cadbiblink.vue");

export default [
  {
    path: "/cadbib_usuarios/:inc:mod:exc:codsis",
    name: "cadbib_usuarios",
    props: true,
    component: cadbib_usuarios,
  },
  {
    path: "/cadbiblink/:inc:mod:exc:codsis",
    name: "cadbiblink",
    props: true,
    component: cadbiblink,
  },
  {
    path: "/conslistaespera/:inc:mod:exc:codsis",
    name: "conslistaespera",
    props: true,
    component: conslistaespera,
  },

  {
    path: "/cadetiquetasavulsas/:inc:mod:exc:codsis",
    name: "cadetiquetasavulsas",
    props: true,
    component: cadetiquetasavulsas,
  },
  {
    path: "/bibiventario/:inc:mod:exc:codsis",
    name: "bibiventario",
    props: true,
    component: bibiventario,
  },
  {
    path: "/cadgeneros/:inc:mod:exc:codsis",
    name: "cadgeneros",
    props: true,
    component: cadgeneros,
  },
  {
    path: "/cadassuntos/:inc:mod:exc:codsis",
    name: "cadassuntos",
    props: true,
    component: cadassuntos,
  },
  {
    path: "/cadeditoras/:inc:mod:exc:codsis",
    name: "cadeditoras",
    props: true,
    component: cadeditoras,
  },
  {
    path: "/cadautores/:inc:mod:exc:codsis",
    name: "cadautores",
    props: true,
    component: cadautores,
  },
  {
    path: "/cadbib_tipo_usuario/:inc:mod:exc:codsis",
    name: "cadbib_tipo_usuario",
    props: true,
    component: cadbib_tipo_usuario,
  },
  {
    path: "/cadlivros_tipoobra/:inc:mod:exc:codsis",
    name: "cadlivros_tipoobra",
    props: true,
    component: cadlivros_tipoobra,
  },
  {
    path: "/cadacervo/:inc:mod:exc:codsis",
    name: "cadacervo",
    props: true,
    component: cadacervo,
  },

];
