<template>
  <div>
    <v-row dense>
      <v-col>
        <v-select
          @change="listaedital"
          outlined
          dense
          hide-details
          label="Unidade"
          :items="unidades"
          item-text="e_nomefantasia"
          item-value="e_id"
          v-model="e_id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-select
          @change="listacursos"
          outlined
          dense
          hide-details
          label="Edital"
          :items="procsel"
          item-text="ps_descricao"
          item-value="ps_id"
          v-model="ps_id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-select
          @change="listaturnos"
          outlined
          dense
          hide-details
          label="Curso"
          :items="cursos"
          item-text="s_descricao"
          item-value="s_id"
          v-model="s_id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-select outlined dense hide-details label="Turno" :items="turnos" item-text="tur_descricao" item-value="tur_id" v-model="tur_id"></v-select>
      </v-col>
      <v-col>
        <v-select
          outlined
          dense
          hide-details
          label="Plano de pgto"
          :items="planopgto"
          item-text="sxv_descricao"
          item-value="sxv_id"
          v-model="sxv_id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <Button label="Confrmar" style="width: 100%;" @click="confirmaincsol" />
      </v-col>
      <v-col cols="6">
        <Button label="Cancelar" style="width: 100%" @click="$emit('cancelar')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import Config, { listaFiliais, listaCursos } from "@/config.ts";

export default {
  name: "solicitacoes-transfinterna",
  props: ["aluno", "m_id", "serv_id"],
  data() {
    return {
      unidades: "",
      cursos: "",
      procsel: "",
      turnos: "",
      planopgto: "",
      e_id: "",
      s_id: "",
      ps_id: "",
      tur_id: "",
      sxv_id: "",
    };
  },
  created() {
    listaFiliais().then((res) => {
      this.unidades = res.dados.data;
      this.e_id = this.unidades[0].e_id;
      this.listaedital(this.e_id);
    });
  },
  methods: {
    listaedital(e_id) {
      http
        .send("", {
          proc: "[fx jaguar matricula lista proc seletivo - transf interna]",
          0: "edital",
          1: e_id,
        })
        .then((res) => {
          if (res.dados.data[0].ps_id) {
            this.procsel = res.dados.data;
            this.ps_id = this.procsel[0].ps_id;
            this.listacursos(this.ps_id);
          } else {
            this.procsel = [{ ps_id: "-1", ps_descricao: "Sem período disponível" }];
            this.ps_id = "-1";
          }
        });
    },
    listacursos(ps_id) {
      http.send("", { proc: "[fx jaguar matricula lista proc seletivo - transf interna]", 0: "cursos", 1: this.e_id, 2: ps_id }).then((res) => {
        this.cursos = res.dados.data.filter((s) => s.s_id !== this.aluno.s_id);
        this.s_id = this.cursos[0].s_id;
        this.listaturnos(this.s_id);
      });
    },
    listaturnos(s_id) {
      var proc_id = this.procsel.filter((p) => p.ps_id === this.ps_id)[0].proc_id;
      http
        .send("", { proc: "[fx jaguar matricula lista proc seletivo - transf interna]", 0: "turnos", 1: this.e_id, 2: this.ps_id, 3: proc_id, 4: s_id })
        .then((res) => {
          try {
            this.turnos = res.dados.data;
            this.tur_id = this.turnos[0].tur_id;
            this.listaplanopgto(this.turnos[0].tur_descricao);
          } catch (e) {
            this.turnos = [{ tur_id: "-1", tur_descricao: "Sem turno disponível" }];
            this.tur_id = this.turnos[0].tur_id;
            this.listaplanopgto(this.turnos[0].tur_descricao);
          }
        });
    },
    async listaplanopgto(turno) {
      console.log("lista plano");
      var proc_id = this.procsel.filter((p) => p.ps_id === this.ps_id)[0].proc_id;
      await http
        .send("", {
          proc: "[fx jaguar matricula lista proc seletivo - transf interna]",
          0: "planopgto",
          1: this.e_id,
          2: this.ps_id,
          3: proc_id,
          4: this.s_id,
          5: turno === "Sem turno disponível" ? "Todos" : turno,
        })
        .then((res) => {
          console.log(res.dados.data);
          this.planopgto = res.dados.data;
          this.sxv_id = this.planopgto[0].sxv_id;
        });
    },

    confirmaincsol() {
      var turno = this.turnos.filter((t) => t.tur_id === this.tur_id)[0].tur_descricao;
      http
        .send("", {
          proc: "[FX jaguar solicitacoes]",
          0: "transfinterna",
          1: this.serv_id,
          2: this.$store.state.contexto.BD[0].usr_id,
          3: this.ps_id,
          4: turno,
          5: this.sxv_id,
          6: this.s_id,
          7: "",
          8: this.aluno.cga,
          9: "",
          10: "0",
        })
        .then((res) => {
          this.$toast.success(`Solicitação ${res.dados.data[0].sol_id} inserida com sucesso`);
          this.$emit("confirmar");
        });
    },
  },
};

/*
Conn.callfunction.send("execute dbo.[FX jaguar solicitacoes] ?,?,?,?,?,?,?,?,?,?,?",["transfinterna",
								  cbxServicosCadastro.selectedItem.serv_id as String,
								  usr ,
								  cbxedital.selectedItem.ps_id,
								  cbxturno.selectedItem.label,
								  cbxplanopgto.selectedItem.sxv_id,
								  cbxcursos.selectedItem.s_id,
								  null,
								  FlexGlobals.topLevelApplication.txtcga.text,
								  complTransf.text,
                                  dgcontr.selectedItem.m_id           as String ]);
*/
</script>

<style></style>
