<template>
  <div>
    <div v-show="increg">
      <v-row dense style="margin-top: 10px">
        <v-col>
          <v-textarea outlined dense hide-details v-model="comentario" label="Discussão/argumento"></v-textarea>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <Button label="Confirmar" @click="confirmar"></Button>
          <Button label="Cancelar" @click="increg = false" style="margin-left: 10px"></Button>
        </v-col>
      </v-row>
    </div>
    <v-row dense>
      <v-col>
        <DataTable
          v-show="!increg"
          style="margin-top: 10px;background-color: #42A5F5; font-size: 12px; "
          :value="registros"
          class="p-datatable-gridlines"
          selectionMode="single"
          :selection.sync="itemSelecionado"
          :scrollable="true"
          scrollHeight="200px"
        >
          <Column headerStyle="width: 4em; background-color: #eee">
            <template #header="item">
              <v-icon
                @click="
                  increg = true;
                  comenario = '';
                "
                title="Incluir novo anexo"
                >mdi mdi-plus-circle</v-icon
              >
            </template>
          </Column>
          <Column headerStyle="background-color: #eee" header="Autoria" field="usr_nome"> </Column>
          <Column headerStyle="background-color: #eee" header="Data" field="sc_data">
            <template #body="item">
              {{ formatData(item.data.sc_data) }}
            </template>
          </Column>
          <Column headerStyle="background-color: #eee" header="Discussão/Argumento" field="sc_comentario"> </Column>
        </DataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import Config, { formatData, formatMoeda, hoje } from "@/config.ts";

export default {
  name: "solicitacoes-discussao",
  props: ["sol_id"],
  data() {
    return {
      formatData,
      increg: false,
      registros: "",
      itemSelecionado: "",
      comentario: "",
    };
  },
  created() {
    this.listaregistros();
  },
  methods: {
    confirmar() {
      http
        .send("", { proc: "[fx jaguar listas]", 1: "incdiscusssol", 2: this.sol_id, 3: this.$store.state.contexto.BD[0].usr_id, 4: this.comentario })
        .then((res) => {
          this.listaregistros();
          this.increg = false;
        });
    },
    listaregistros() {
      http.send("", { proc: "[fx jaguar listas]", 1: "listadiscusssol", 2: this.sol_id }).then((res) => {
        if (res.dados.data[0].usr_id) {
          this.registros = res.dados.data;
        } else {
          this.registros = "";
        }
      });
    },
  },
};
</script>

<style></style>
