const relatorios     = () => import("@/views/modulos/relatorios/relatorios.vue");

const selperiododedata = () => import("@/views/modulos/relatorios/selperiododedata");
const selperiodomesanoprocesso = () => import("@/views/modulos/relatorios/selperiodomesanoprocesso");
const selrubperiododata = () => import("@/views/modulos/relatorios/selrubperiododata");
const prepararel = () => import("@/views/modulos/relatorios/prepararel");
const selperiodoacademicobeneficios = () => import("@/views/modulos/relatorios/selperiodoacademicobeneficios");
const selperiododedatacomperiodoacademico = () => import("@/views/modulos/relatorios/selperiododedatacomperiodoacademico");
const selbeneficios = () => import("@/views/modulos/relatorios/selbeneficios");
const selcursobeneficios = () => import("@/views/modulos/relatorios/selcursobeneficios");
const selcursos = () => import("@/views/modulos/relatorios/selcursos");
const selperiodomesano = () => import("@/views/modulos/relatorios/selperiodomesano");
const selcursosperiodo = () => import("@/views/modulos/relatorios/selcursosperiodo");
const selperiodo = () => import("@/views/modulos/relatorios/selperiodo");
const selturmas = () => import("@/views/modulos/relatorios/selturmas");
const seldisciplinas = () => import("@/views/modulos/relatorios/seldisciplinas");
const selexercicio = () => import("@/views/modulos/relatorios/selexercicio");
const selperiododedatarubrica = () => import("@/views/modulos/relatorios/selperiododedatarubrica");
const gerarelatoriosemparametro = () => import("@/views/modulos/relatorios/gerarelatoriosemparametro");
const selperiodoano = () => import("@/views/modulos/relatorios/selperiodoano");
const selperiodotiposaida = () => import("@/views/modulos/relatorios/selperiodotiposaida");
const crmselempresas = () => import("@/views/modulos/relatorios/crmselempresas");
const selperiodoexercicio = () => import("@/views/modulos/relatorios/selperiodoexercicio");

export default [
  {
    path: "/relatorios",
    name: "relatorios",
    props: true,
    component: relatorios,
  },
  {
    path: "/selperiodoexercicio/:inc:mod:exc:codsis",
    name: "selperiodoexercicio",
    props: true,
    component: selperiodoexercicio,
  },
  {
    path: "/selperiodotiposaida/:inc:mod:exc:codsis",
    name: "selperiodotiposaida",
    props: true,
    component: selperiodotiposaida,
  },

    {
    path: "/crmselempresas/:inc:mod:exc:codsis",
    name: "crmselempresas",
    props: true,
    component: crmselempresas,
  },

  {
    path: "/gerarelatoriosemparametro/:inc:mod:exc:codsis",
    name: "gerarelatoriosemparametro",
    props: true,
    component: gerarelatoriosemparametro,
  },
    {
    path: "/selperiodoano/:inc:mod:exc:codsis",
    name: "selperiodoano",
    props: true,
    component: selperiodoano,
  },

  {
    path: "/selperiododedatarubrica/:inc:mod:exc:codsis",
    name: "selperiododedatarubrica",
    props: true,
    component: selperiododedatarubrica,
  },
  {
    path: "/selperiodomesanoprocesso/:inc:mod:exc:codsis",
    name: "selperiodomesanoprocesso",
    props: true,
    component: selperiodomesanoprocesso,
  },
  {
    path: "/selrubperiododata/:inc:mod:exc:codsis",
    name: "selrubperiododata",
    props: true,
    component: selrubperiododata,
  },
  {
    path: "/prepararel/:inc:mod:exc:codsis",
    name: "prepararel",
    props: true,
    component: prepararel,
  },
  {
    path: "/selperiodoacademicobeneficios/:inc:mod:exc:codsis",
    name: "selperiodoacademicobeneficios",
    props: true,
    component: selperiodoacademicobeneficios,
  },
  {
    path: "/selperiododedatacomperiodoacademico/:inc:mod:exc:codsis",
    name: "selperiododedatacomperiodoacademico",
    props: true,
    component: selperiododedatacomperiodoacademico,
  },
  {
    path: "/selbeneficios/:inc:mod:exc:codsis",
    name: "selbeneficios",
    props: true,
    component: selbeneficios,
  },
  {
    path: "/selcursobeneficios/:inc:mod:exc:codsis",
    name: "selcursobeneficios",
    props: true,
    component: selcursobeneficios,
  },
  {
    path: "/selperiodomesano/:inc:mod:exc:codsis",
    name: "selperiodomesano",
    props: true,
    component: selperiodomesano,
  },
  {
    path: "/selcursosperiodo/:inc:mod:exc:codsis",
    name: "selcursosperiodo",
    props: true,
    component: selcursosperiodo,
  },
  {
    path: "/selcursos/:inc:mod:exc:codsis",
    name: "selcursos",
    props: true,
    component: selcursos,
  },
  {
    path: "/selperiodo/:inc:mod:exc:codsis",
    name: "selperiodo",
    props: true,
    component: selperiodo,
  },
  {
    path: "/selexercicio/:inc:mod:exc:codsis",
    name: "selexercicio",
    props: true,
    component: selexercicio,
  },
  {
    path: "/selperiododedata/:inc:mod:exc:codsis",
    name: "selperiododedata",
    props: true,
    component: selperiododedata,
  },
  {
    path: "/selturmas/:inc:mod:exc:codsis",
    name: "selturmas",
    props: true,
    component: selturmas,
  },
  {
    path: "/seldisciplinas/:inc:mod:exc:codsis",
    name: "seltdisciplinas",
    props: true,
    component: seldisciplinas,
  },

    
];
