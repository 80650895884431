const cadnpsprojetos = () => import("@/views/modulos/nucleosdepratica/nps/cadnpsprojetos.vue");


export default [
  {
    path: "/cadnpsprojetos/:inc:mod:exc:codsis",
    name: "cadnpsprojetos",
    props: true,
    component: cadnpsprojetos,
  },
 
]