const sysprodutos     = () => import("@/views/modulos/loja/sysprodutos.vue");
const sysclientes     = () => import("@/views/modulos/loja/sysclientes.vue");
const alunosplataforma= () => import("@/views/modulos/loja/alunosplataforma.vue");
const lojaProdutos= () => import("@/views/modulos/loja/loja-produtos.vue");
const lojaVenda= () => import("@/views/modulos/loja/loja-venda.vue");
  
export default [
  {
    path: "/loja-produtos/:inc:mod:exc:codsis",
    name: "lojaprodutos",
    props: true,
    component: lojaProdutos,
  },

  {
    path: "/loja-venda/:inc:mod:exc:codsis",
    name: "lojavenda",
    props: true,
    component: lojaVenda,
  },


  {
    path: "/sysprodutos/:inc:mod:exc:codsis",
    name: "sysprodutos",
    props: true,
    component: sysprodutos,
  },

  {
    path: "/sysclientes/:inc:mod:exc:codsis",
    name: "sysclientes",
    props: true,
    component: sysclientes,
  },

  {
    path: "/alunosplataforma/:inc:mod:exc:codsis",
    name: "alunosplataforma",
    props: true,
    component: alunosplataforma,
  },
  
];
