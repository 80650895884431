const cadfuncionarios     = () => import("@/views/modulos/rh/cadfuncionarios.vue");
const caddepartamento     = () => import("@/views/modulos/rh/caddepartamento.vue");
const cadfaixa_salarial   = () => import("@/views/modulos/rh/cadfaixa_salarial.vue");
const cadcargos   = () => import("@/views/modulos/rh/cadcargos.vue");
const cadgrau_instrucao   = () => import("@/views/modulos/rh/cadgrau_instrucao.vue");
const cadregime_contratacao   = () => import("@/views/modulos/rh/cadregime_contratacao.vue");
const cadprofissoes = () => import("@/views/modulos/rh/cadprofissoes.vue");
const cadrecselEmpresas = () => import("@/views/modulos/rh/cadrecsel-empresas.vue")
const cadrecselProfissoes = () => import("@/views/modulos/rh/cadrecsel-profissoes.vue")
const cadrecselCargos = () => import("@/views/modulos/rh/cadrecsel-cargos.vue")  
const cadrecselCandidatos = () => import("@/views/modulos/rh/cadrecsel-candidatos.vue")
const cadrecselControle= () => import("@/views/modulos/rh/cadrecsel-controle.vue")
const cadrecselFases= () => import("@/views/modulos/rh/cadrecsel-fases.vue")
const cadrhfilial = () => import("@/views/modulos/rh/cadrhfilial.vue")
const cadjornada = () => import("@/views/modulos/rh/cadjornada.vue")
const cadfolhapgto = () => import("@/views/modulos/rh/cadfolhapgto.vue")
const cadrhverbas = () => import("@/views/modulos/rh/cadrhverbas.vue")
const cadrhbeneficios = () => import("@/views/modulos/rh/cadrhbeneficios.vue")
const cadrhfardamento = () => import("@/views/modulos/rh/cadrhfardamento.vue")
  
export default [
  {
    path: "/cadrhfardamento/:inc:mod:exc:codsis",
    name: "cadrhfardamento",
    props: true,
    component: cadrhfardamento,
  },
  {
    path: "/cadrhbeneficios/:inc:mod:exc:codsis",
    name: "cadrhbeneficios",
    props: true,
    component: cadrhbeneficios,
  },
  {
    path: "/cadrhverbas/:inc:mod:exc:codsis",
    name: "cadrhverbas",
    props: true,
    component: cadrhverbas,
  },
  {
    path: "/cadfolhapgto/:inc:mod:exc:codsis",
    name: "cadfolhapgto",
    props: true,
    component: cadfolhapgto,
  },
  {
    path: "/cadjornada/:inc:mod:exc:codsis",
    name: "cadjornada",
    props: true,
    component: cadjornada,
  },
  {
    path: "/cadfuncionarios/:inc:mod:exc:codsis",
    name: "cadfuncionarios",
    props: true,
    component: cadfuncionarios,
  },
   {
    path: "/cadrhfilial/:inc:mod:exc:codsis",
    name: "cadrhfilial",
    props: true,
    component: cadrhfilial,
  },
 
    {
    path: "/cadrecsel-candidatos/:inc:mod:exc:codsis",
    name: "cadrecsel-candidatos",
    props: true,
    component: cadrecselCandidatos,
  },
    {
    path: "/cadrecsel-fases/:inc:mod:exc:codsis",
    name: "cadrecsel-fases",
    props: true,
    component: cadrecselFases,
  },    
    
    {
    path: "/cadrecsel-controle/:inc:mod:exc:codsis",
    name: "cadrecsel-controle",
    props: true,
    component: cadrecselControle,
  },    

    {
    path: "/cadrecsel-empresas/:inc:mod:exc:codsis",
    name: "cadrecsel-empresas",
    props: true,
    component: cadrecselEmpresas,
  },    

    {
    path: "/cadrecsel-cargos/:inc:mod:exc:codsis",
    name: "cadrecsel-cargos",
    props: true,
    component: cadrecselCargos,
  },    
    
    {
    path: "/cadrecsel-profissoes/:inc:mod:exc:codsis",
    name: "cadrecsel-profissoes",
    props: true,
    component: cadrecselProfissoes,
  },    

  {
    path: "/caddepartamento/:inc:mod:exc:codsis",
    name: "caddepartamento",
    props: true,
    component: caddepartamento,
  },    
    {
    path: "/cadfaixa_salarial/:inc:mod:exc:codsis",
    name: "cadfaixa_salarial",
    props: true,
    component: cadfaixa_salarial,
  },    
    {
    path: "/cadcargos/:inc:mod:exc:codsis",
    name: "cadcargos",
    props: true,
    component: cadcargos,
  },    
    {
    path: "/cadgrau_instrucao/:inc:mod:exc:codsis",
    name: "cadgrau_instrucao",
    props: true,
    component: cadgrau_instrucao,
  },    
    {
    path: "/cadregime_contratacao/:inc:mod:exc:codsis",
    name: "cadregime_contratacao",
    props: true,
    component: cadregime_contratacao,
  },    
    {
    path: "/cadprofissoes/:inc:mod:exc:codsis",
    name: "cadprofissoes",
    props: true,
    component: cadprofissoes,
  },    

];
