
const caddisciplinas        = () => import("../caddisciplinas.vue");
const cadescolas            = () => import("../cadescolas.vue");
const caddisciplinasserie   = () => import("../caddisciplinas_serie.vue");
const cadmiperiodoletivo    = () => import("../cadmiperiodoletivo.vue");
const cadmiturmas           = () => import("../cadmiturmas.vue");
const cadmimembros          = () => import("../cadmimembros.vue");
const cadmicursos           = () => import("../cadmicursos.vue");
const cadmicurriculo        = () => import("../cadmicurriculo.vue");
const cadmidisciplina       = () => import("../cadmidisciplina.vue");
const cadmimatrizcurricular = () => import("../cadmimatrizcurricular.vue");
const cadminivelacademico   = () => import("../cadminivelacademico.vue");
const cadmisalas            = () => import("../cadmisalas.vue");
const cadmicalendario       = () => import("../cadmicalendario.vue");
const cadminatprocsel       = () => import("../cadminatprocsel.vue");
const cadmiprocsel          = () => import("../cadmiprocsel.vue");
const cadmipessoas          = () => import("../cadmipessoas.vue");
const cadmicompetencias     = () => import("../cadmicompetencias.vue");
const cadmihabilidades      = () => import("../cadmihabilidades.vue");
const cadmitipopendencia    = () => import("../cadmitipopendencia.vue");
const cadmitipoocorrencia   = () => import("../cadmitipoocorrencia.vue");
const cadmitipodoc          = () => import("../cadmitipodoc.vue");
const cadmiofertaturma      = () => import("../cadmiofertaturma.vue");
const cadmifrequencia       = () => import("../cadmifrequencia.vue");

export default [
  {
    name: "cadmifrequencia",
    path: "/cadmifrequencia/:inc:mod:exc:codsis",
    component: cadmifrequencia,
    props: true
  },
  {
    name: "cadmiofertaturma",
    path: "/cadmiofertaturma/:inc:mod:exc:codsis",
    component: cadmiofertaturma,
    props: true
  },
  {
    name: "cadmitipodoc",
    path: "/cadmitipodoc/:inc:mod:exc:codsis",
    component: cadmitipodoc,
    props: true
  },
  {
    name: "cadmitipoocorrencia",
    path: "/cadmitipoocorrencia/:inc:mod:exc:codsis",
    component: cadmitipoocorrencia,
    props: true
  },
  {
    name: "cadmitipopendencia",
    path: "/cadmitipopendencia/:inc:mod:exc:codsis",
    component: cadmitipopendencia,
    props: true
  },
  {
    name: "cadmihabilidades",
    path: "/cadmihabilidades/:inc:mod:exc:codsis",
    component: cadmihabilidades,
    props: true
  },
  {
    name: "cadmicompetencias",
    path: "/cadmicompetencias/:inc:mod:exc:codsis",
    component: cadmicompetencias,
    props: true
  },
  {
    name: "cadmipessoas",
    path: "/cadmipessoa/:inc:mod:exc:codsis",
    component: cadmipessoas,
    props: true
  },
  {
    name: "cadmiprocsel",
    path: "/cadmiprocsel/:inc:mod:exc:codsis",
    component: cadmiprocsel,
    props: true
  },
  {
    name: "cadminatprocsel",
    path: "/cadminatprocsel/:inc:mod:exc:codsis",
    component: cadminatprocsel,
    props: true
  },
  {
    name: "cadmicalendario",
    path: "/cadmicalendario/:inc:mod:exc:codsis",
    component: cadmicalendario,
    props: true
  },
  {
    name: "cadmisalas",
    path: "/cadmisalas/:inc:mod:exc:codsis",
    component: cadmisalas,
    props: true
  },
  {
    name: "cadminivelacademico",
    path: "/cadminivelacademico/:inc:mod:exc:codsis",
    component: cadminivelacademico,
    props: true
  },
  {
    name: "cadmimatrizcurricular",
    path: "/cadmimatrizcurricular/:inc:mod:exc:codsis",
    component: cadmimatrizcurricular,
    props: true
  },
  {
    name: "cadmidisciplina",
    path: "/cadmidisciplina/:inc:mod:exc:codsis",
    component: cadmidisciplina,
    props: true
  },
  {
    name: "cadmicurriculo",
    path: "/cadmicurriculo/:inc:mod:exc:codsis",
    component: cadmicurriculo,
    props: true
  },
  {
    name: "cadmicursos",
    path: "/cadmicursos/:inc:mod:exc:codsis",
    component: cadmicursos,
    props: true
  },
  {
    name: "cadmimembros",
    path: "/cadmimembros/:inc:mod:exc:codsis",
    component: cadmimembros,
    props: true
  },
  {
    name: "caddisciplinas",
    path: "/caddisciplinas/:inc:mod:exc:codsis",
    component: caddisciplinas,
    props: true
  },
  {
    name: "cadmiperiodoletivo",
    path: "/cadmiperiodoletivo/:inc:mod:exc:codsis",
    component: cadmiperiodoletivo,
    props: true
  },
  {
    name: "cadmiturmas",
    path: "/cadmiturmas/:inc:mod:exc:codsis",
    component: cadmiturmas,
    props: true
  },
  {
    name: "cadescolas",
    path: "/cadescolas/:inc:mod:exc:codsis",
    component: cadescolas,
    props: true
  },
  {
    name: "caddisciplinas_serie",
    path: "/caddisciplinas_serie",
    component: caddisciplinasserie
  }
];
