<template>
  <div>
    <v-row dense>
      <v-col cols="6">
        <v-select :items="disciplinas" item-value="nd_id" item-text="d_descricao" label="Disciplina" outlined dense v-model="nd_id" @change="montatexto" />
      </v-col>
      <v-col cols="6">
        <div>
          <v-file-input
            prepend-icon=""
            v-model="arquivos"
            outlined
            dense
            label="Docs para analise da justificativa"
            multiple
            prepend-inner-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </div>
      </v-col>

    </v-row>

    <v-row dense style="margin-top: -10px">
      <v-col>
        <v-textarea rows="4" label="Justificativa" outlined dense v-model="just">
        </v-textarea>
      </v-col>
    </v-row>
    <v-row dense style="margin-top: -20px">
      <v-col>
        <Button label="Confirmar" @click="confirmar"></Button>
        <Button label="Cancelar" @click="cancelar" style="margin-left: 5px"></Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import axios from "axios";
import Config, { formatData, formatMoeda, hoje } from "@/config.ts";

export default {
  name: "segchamada",
  props: ["disciplinas", "aluno", "serv_id", "m_id"],
  data() {
    return {
      just: "",
      nd_id: "",
      aulas: [],
      dataaula: "",
      dataini: "",
      datafim: "",
      arquivos: null,
    };
  },
  created() {
    this.nd_id = this.disciplinas[0].nd_id;
  },
  methods: {
    montatexto() {
      this.just =
        "O aluno " +
        this.aluno.a_nome +
        ", CGA " +
        this.aluno.cga.trim() +
        ", curso de " +
        this.aluno.s_descricao.trim() +
        ", solicita segunda chamada de avaliaçao " +
        " da disciplina " +
        this.disciplinas.filter((d) => d.nd_id === this.nd_id)[0].d_descricao +
        " em que deixou/deixará de realizar por motivo de ";
    },
    
    confirmar() {
      http
        .send("", {
          proc: "[FX jaguar solicitacoes]",
          1: "segchamada",
          2: this.serv_id,
          3: this.$store.state.contexto.BD[0].usr_id,
          4: this.nd_id,
          5: '-1', // this.pd_id
          6: '-1', // this.av_id
          7: "",
          8: this.m_id,
          9: this.just,
        })
        .then((res) => {
          this.sol_id = res.dados.data[0].sol_id;
          if (this.arquivos) {
          
          for (let index = 0; index < this.arquivos.length; index++) {
            this.upload(this.arquivos[index]);
          }
        }

          this.$toast.success("Solicitação incluída com sucesso");
          this.$emit("confirmar",{relatorio: res.dados.data[0].relatorio, sol_id: res.dados.data[0].sol_id});
        });
    },

    async upload(file) {
      await http.send("", { proc: "[fx jaguar listas]", 1: "incanexosol", 2: this.sol_id, 3: file.name }).then((res) => {
        const formData = new FormData();
        const url =
          Config.baseApiUrlUpload +
          `/upload?destino=${this.$store.state.contexto.BD[0].bco_pasta.trim()}/alunos/${this.aluno.cga
            .replace(".", "")
            .replace(".", "")
            .replace("-", "")
            .trim() + "/solicitacoes/"}&codigo=${this.sol_id}`;

        formData.append("file", file);

        axios.post(url, formData).then((res) => {
          this.$toast.success("uploaded " + file.name);
        });
      });
    },

    cancelar() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style></style>
