<template>
  <div>
    <SplitButton label="Favoritos" icon="pi pi-heart" class="p-mb-2 p-button-primary" :model="items"></SplitButton>
  </div>
</template>

<script>
export default {
  data: () => ({
    active: "guide",
    items: [],
  }),
  mounted() {
    console.log("menufav");
    this.items = [];
    this.$store.state.contexto.favoritos.forEach((element) => {
      console.log(element.titulo);
      this.items.push({ label: element.titulo, command: element.funcao });
    });
  },
  created() {
    console.log("menufav");
    this.items = [];
    this.$store.state.contexto.favoritos.forEach((element) => {
      console.log(element.titulo);
      this.items.push({ label: element.titulo, command: element.funcao });
    });
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.p-splitbutton {
  margin-right: 0.5rem;
}
</style>
