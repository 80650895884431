<template>
  <div>
    <v-row dense style="margin-top: -20px">
      <v-col>
        <v-select outlined dense hide-details label="Especificação" v-model="sxe_id" :items="registros" item-text="sxe_descricao" item-value="sxe_id" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-textarea outlined dense hide-details label="Complemento" v-model="just"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Button label="Confirmar" @click="confirmar"></Button>
        <Button label="Cancelar" @click="cancelar" style="margin-left: 10px"></Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import Config, { formatData, formatMoeda, hoje } from "@/config.ts";

export default {
  name: "solicitacoes-comespecificacao",
  props: ["serv_id", "aluno", "m_id"],
  data() {
    return {
      registros: "",
      sxe_id: "",
      just: "",
    };
  },
  created() {
    http.send("", { proc: "[fx jaguar listas]", 1: "listaespec", 2: this.serv_id }).then((res) => {
      this.registros = res.dados.data;
      this.sxe_id = res.dados.data[0].sxe_id;
    });
  },
  methods: {
    cancelar() {
      this.$emit("cancelar");
    },
    confirmar() {
      http
        .send("", {
          proc: "[FX jaguar solicitacoes]",
          1: "generico",
          2: this.serv_id,
          3: this.$store.state.contexto.BD[0].usr_id,
          4: this.sxe_id,
          5: "",
          6: "",
          7: this.just,
          8: this.m_id,
        })
        .then((res) => {
          console.log("dados");
          console.log(res.dados);
          this.$emit("confirmar");
        });
    },
  },
};
</script>

<style></style>
