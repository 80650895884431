const cadprodutos     = () => import("@/views/modulos/almox/cadprodutos.vue");
const inventario     = () => import("@/views/modulos/almox/inventario.vue");
const almox_solicitarmaterial = () => import("@/views/modulos/almox/almox_solicitarmaterial.vue");
export default [
  {
    path: "/cadprodutos/:inc:mod:exc:codsis",
    name: "cadprodutos",
    props: true,
    component: cadprodutos,
  },
    {
    path: "/inventario/:inc:mod:exc:codsis",
    name: "inventario",
    props: true,
    component: inventario,
  },
    {
    path: "/almox_solicitarmaterial/:inc:mod:exc:codsis",
    name: "almox_solicitarmaterial",
    props: true,
    component: almox_solicitarmaterial,
  }       
];
