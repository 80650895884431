import "@mdi/font/css/materialdesignicons.min.css";
import "@fortawesome/fontawesome-pro/css/all.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free-6.0.0/css/all.css"; // Ensure you are using css-loader

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
});
