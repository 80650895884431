<template>
  <div>
    <v-row dense style="margin-top: -20px">
      <v-col cols="11">
        <v-select outlined dense hide-details label="Disciplinas" :items="disciplinas" item-text="d_descricao" item-value="ds_id" v-model="ds_id" />
      </v-col>
      <v-col cols="1">
        <Button class="p-butonn-sm" style="width: 100%; font-size:20px; font-weight: bold" icon="mdi mdi-plus-circle" @click="incluir"></Button>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-textarea rows="2" outlined dense hide-details label="Complemento" v-model="just"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-badge :content="registros.length" :value="registros.length" overlap bordered left>
          <DataTable
            style="font-size: 12px;"
            :value="registros"
            class="p-datatable-gridlines"
            selectionMode="single"
            :selection.sync="itemSelecionado"
            :scrollable="true"
            scrollHeight="205px"
          >
            <Column headerStyle="width: 5px">
              <template #body="item">
                <v-icon
                  color="red"
                  @click="
                    itemSelecionado = item.data;
                    excl = true;
                  "
                  >mdi mdi-minus-circle</v-icon
                >
              </template>
            </Column>
            <Column bodyStyle="width: 50px;" field="d_descricao" headerStyle="width: 50px" header="Material"></Column>
            <Column field="valor" bodyStyle="width: 15px; text-align: right" headerStyle="width: 15px; text-align: right" header="Vl Unitário">
              <template #body="item">
                {{ formatMoeda(item.data.valor) }}
              </template>
            </Column>
          </DataTable>
        </v-badge>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <div style="float: right; font-weight: 700; margin-right: 15px">Total: {{ formatMoeda(soma) }}</div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <Button label="Confirmar" @click="confirmar"></Button>
        <Button label="Cancelar" @click="cancelar" style="margin-left:5px"></Button>
      </v-col>
    </v-row>
    <v-dialog v-model="excl" width="300">
      <confirme @senao="excl = false" @sesim="excluir"></confirme>
    </v-dialog>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import Config, { formatData, formatMoeda, hoje } from "@/config.ts";
import confirme from "@/components/confirme";

export default {
  name: "solicitacoes-conteudoprog",
  props: ["aluno", "serv_id", "valor", "m_id"],
  components: { confirme },
  data() {
    return {
      formatMoeda,
      disciplinas: "",
      excl: false,
      ds_id: "",
      just: "",
      itemSelecionado: "",
      registros: [],
      soma: 0,
    };
  },
  watch: {
    registros: function(novo, antigo) {
      this.soma = 0;
      this.just = "Aluno solicita seguintes ementas: ";
      for (let index = 0; index < this.registros.length; index++) {
        this.soma = parseFloat(this.soma) + parseFloat(this.registros[index].valor.replace(",", "."));
        this.just = this.just + this.registros[index].d_descricao + "; ";
      }
    },
  },
  created() {
    http.send("", { proc: "[fx jaguar listas]", 1: "listadiscipconteudoprogsolicitacoes", 2: this.aluno.cga }).then((res) => {
      this.disciplinas = res.dados.data;
      this.ds_id = this.disciplinas[0].ds_id;
    });
  },
  methods: {
    confirmar() {
      var s = "";
      for (let index = 0; index < this.registros.length; index++) {
        s = s + this.registros[index].ds_id + ",";
      }
      http
        .send("", {
          proc: `[FX jaguar solicitacoes] "conteudo",${this.serv_id},${this.$store.state.contexto.BD[0].usr_id},'${s.substring(
            1,
            s.length - 1
          )}',${this.registros.length.toString()},null,'${this.just}',${this.m_id}`,
          /*          1: "conteudo",
          2: this.serv_id,
          3: this.$store.state.contexto.BD[0].usr_id,
          4: s.substring(1, s.length - 1),
          5: this.registros.length.toString(),
          6: "",
          7: this.just,
          8: this.m_id, */
        })
        .then((res) => {
          console.log(res.dados);
          this.$toast.success("Solicitação incluida com sucesso");
          this.$emit("confirmar");
        });
    },
    cancelar() {
      this.$emit("cancelar");
    },
    incluir() {
      var inc = true;
      for (let index = 0; index < this.registros.length; index++) {
        if (this.registros[index].ds_id === this.ds_id) {
          inc = false;
        }
      }
      if (inc) {
        this.registros.push({ ds_id: this.ds_id, d_descricao: this.disciplinas.filter((d) => d.ds_id === this.ds_id)[0].d_descricao, valor: this.valor });
      }
    },
    excluir() {
      this.registros = this.registros.filter((r) => r.ds_id !== this.itemSelecionado.ds_id);
      this.excl = false;
    },
  },
};
</script>

<style></style>
