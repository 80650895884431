<template>
  <v-card
    :width="width === undefined ? 300 : width"
    :height="height === undefined ? 160 : height"
    style="position: relative; overflow: hidden"
  >
    <v-system-bar
      color="#01579B"
      dark
      style="padding-top: 10px; height: 50px; padding-bottom: 20px"
    >
      <div
        style="
          width: 100%;
          color: white;
          font-size: 15px;
          font-weight: 550;
          text-align: center;
        "
      >
        Confirmar
      </div>
      <v-spacer></v-spacer>
      <!--
      <v-icon style="margin-left: 10px; font-weight: 350; font-size: 18px; color: white">fal fa-question-circle</v-icon>
-->
    </v-system-bar>
    <v-card-text style="background-color: #eee">
      <v-row>
        <v-col cols="12">
          <div
            style="text-align: center; height: 160px; line-height: 50px"
            v-html="
              msg === undefined ? 'Confirma a exclusão do registro?' : msg
            "
          ></div>
          <v-footer
            absolute
            style="border-top: 1px solid #ccc; background-color: #eee"
          >
            <v-spacer></v-spacer>
            <div style="float: right; background-color: #eee">
              <Button
                label="Sim"
                icon="mdi mdi-check"
                class="p-button-sm"
                style="padding: 5px"
                @click="sesim"
              ></Button>
              <Button
                icon="mdi mdi-close"
                label="Não"
                class="p-button-sm"
                style="margin-left: 5px; background-color: #666; padding: 5px"
                @click="senao"
              ></Button>
            </div>
          </v-footer>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "confirme",
  props: ["msg", "width", "height"],
  data() {
    return {
      confirm: true,
    };
  },
  methods: {
    sesim() {
      this.$emit("sesim");
    },
    senao() {
      this.$emit("senao");
    },
  },
};
</script>

<style></style>
