const cadbens     = () => import("@/views/modulos/patrimonio/cadbens.vue");
const cadbens_tipo     = () => import("@/views/modulos/patrimonio/cadbens_tipo.vue");
const cadveiculos     = () => import("@/views/modulos/patrimonio/cadveiculos.vue");

export default [
  {
    path: "/cadbens/:inc:mod:exc:codsis",
    name: "cadbens",
    props: true,
    component: cadbens,
  },
  {
    path: "/cadbens_tipo/:inc:mod:exc:codsis",
    name: "cadbens_tipo",
    props: true,
    component: cadbens_tipo,
  },  
  {
    path: "/cadveiculos/:inc:mod:exc:codsis",
    name: "cadveiculos",
    props: true,
    component: cadveiculos,
  },  
];
