const cadcajpessoas     = () => import("@/views/modulos/igreja/cadcajpessoas.vue");
const cadcajdenominacoes = () => import ('@/views/modulos/igreja/router/cadcajdenominacoes')
const cadigrejatipoingresso = () => import("@/views/modulos/igreja/cadigrejatipoingresso")
const cadigrejatipoconfissao = () => import("@/views/modulos/igreja/cadigrejatipoconfissao")
const cadigrejadepartamentos = () => import("@/views/modulos/igreja/cadigrejadepartamentos")
const cadigrejacargos = () => import("@/views/modulos/igreja/cadigrejacargos")
const cadigrejatipoocorrencia = () => import("@/views/modulos/igreja/cadigrejatipoocorrencia")
const cadigrejafuncoespeqgrupo = () => import("@/views/modulos/igreja/cadigrejafuncoespeqgrupo")
const cadigrejapeqgrupo = () => import("@/views/modulos/igreja/cadigrejapeqgrupo")
const cadigrejapeqgrupoperfil = () => import("@/views/modulos/igreja/cadigrejapeqgrupoperfil")
const cadigrejapessoas = () => import("@/views/modulos/igreja/cadigrejapessoas")  
const cadigrejaprojetossociais = () => import("@/views/modulos/igreja/cadigrejaprojetossociais")
const cadigrejatipoatracao = () => import("@/views/modulos/igreja/cadigrejatipoatracao")
const cadigrejadashboard = () => import("@/views/modulos/igreja/cadigrejadashboard")
const cadigrejaboletim = () => import("@/views/modulos/igreja/cadigrejaboletim")
const cadigrejastoreprodservcategorias = () => import("@/views/modulos/igreja/cadigrejastoreprodservcategorias")
const cadigrejastoreprodserv = () => import("@/views/modulos/igreja/cadigrejastoreprodserv")
const cadigrejasoliciotacoes = () => import('@/views/modulos/igreja/cadigrejasoliciotacoes')
const agendageral = () => import('@/views/modulos/igreja/agenda-geral')
  
export default [

              {
    path: "/agendageral/:inc:mod:exc:codsis",
    name: "agendageral",
    props: true,
    component: agendageral,
  },    

            {
    path: "/cadigrejasoliciotacoes/:inc:mod:exc:codsis",
    name: "cadigrejasoliciotacoes",
    props: true,
    component: cadigrejasoliciotacoes,
  },    

          {
    path: "/cadigrejastoreprodserv/:inc:mod:exc:codsis",
    name: "cadigrejastoreprodserv",
    props: true,
    component: cadigrejastoreprodserv,
  },    

        {
    path: "/cadigrejastoreprodservcategorias/:inc:mod:exc:codsis",
    name: "cadigrejastoreprodservcategorias",
    props: true,
    component: cadigrejastoreprodservcategorias,
  },    

      {
    path: "/cadigrejaboletim/:inc:mod:exc:codsis",
    name: "cadigrejaboletim",
    props: true,
    component: cadigrejaboletim,
  },    

    {
    path: "/cadigrejadashboard/:inc:mod:exc:codsis",
    name: "cadigrejadashboard",
    props: true,
    component: cadigrejadashboard,
  },    

  {
    path: "/cadigrejatipoatracao/:inc:mod:exc:codsis",
    name: "cadigrejatipoatracao",
    props: true,
    component: cadigrejatipoatracao,
  },    

  {
    path: "/cadigrejaprojetossociais/:inc:mod:exc:codsis",
    name: "cadigrejaprojetossociais",
    props: true,
    component: cadigrejaprojetossociais,
  },    


  {
    path: "/cadigrejapessoas/:inc:mod:exc:codsis",
    name: "cadigrejapessoas",
    props: true,
    component: cadigrejapessoas,
  },    
  
  {
    path: "/cadigrejapeqgrupoperfil/:inc:mod:exc:codsis",
    name: "cadigrejapeqgrupoperfil",
    props: true,
    component: cadigrejapeqgrupoperfil,
  },    
  {
    path: "/cadigrejapeqgrupo/:inc:mod:exc:codsis",
    name: "cadigrejapeqgrupo",
    props: true,
    component: cadigrejapeqgrupo,
  },    
  {
    path: "/cadigrejafuncoespeqgrupo/:inc:mod:exc:codsis",
    name: "cadigrejafuncoespeqgrupo",
    props: true,
    component: cadigrejafuncoespeqgrupo,
  },    
  {
    path: "/cadigrejatipoocorrencia/:inc:mod:exc:codsis",
    name: "cadigrejatipoocorrencia",
    props: true,
    component: cadigrejatipoocorrencia,
  },    

  {
    path: "/cadigrejacargos/:inc:mod:exc:codsis",
    name: "cadigrejacargos",
    props: true,
    component: cadigrejacargos,
  },    

  {
    path: "/cadigrejadepartamentos/:inc:mod:exc:codsis",
    name: "cadigrejadepartamentos",
    props: true,
    component: cadigrejadepartamentos,
  },    
  {
    path: "/cadigrejatipoconfissao/:inc:mod:exc:codsis",
    name: "cadigrejatipoconfissao",
    props: true,
    component: cadigrejatipoconfissao,
  },    
  {
    path: "/cadigrejatipoingresso/:inc:mod:exc:codsis",
    name: "cadigrejatipoingresso",
    props: true,
    component: cadigrejatipoingresso,
  },    
  {
    path: "/cadcajpessoas/:inc:mod:exc:codsis",
    name: "cadcajpessoas",
    props: true,
    component: cadcajpessoas,
  },    
    {
    path: "/cadcajdenominacoes/:inc:mod:exc:codsis",
    name: "cadcajdenominacoes",
    props: true,
    component: cadcajdenominacoes,
  },    

];
