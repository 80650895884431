<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"
        ><span style="font-size: 12px">Aguarde</span></v-progress-circular
      >
    </v-overlay>

    <v-row dense style="margin-top: 10px">
      <v-col>
        <v-text-field
          v-model="nomefunc"
          @keypress.enter="pesqfunc"
          outlined
          dense
          hide-details
          label="Operador"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <DataTable
          style="background-color: #42a5f5; font-size: 12px"
          :value="funcionarios"
          class="p-datatable-gridlines"
          selectionMode="single"
          :selection.sync="funcSelecionado"
          :scrollable="true"
          scrollHeight="125px"
        >
          <Column
            header="Nome"
            field="usr_nome"
            headerStyle="background-color: #eee"
          />
        </DataTable>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-textarea
          rows="3"
          outlined
          dense
          hide-details
          label="Orientação para o destinatário"
          v-model="just"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-select
          outlined
          dense
          hide-details
          item-value="label"
          item-text="label"
          label="Natureza do encaminhamento"
          v-model="natureza"
          :items="[
            { label: 'CONHECIMENTO' },
            { label: 'ANÁLISE' },
            { label: 'PROVIDENCIAS' },
            { label: 'DEFERIMENTO' },
            { label: 'EXECUÇÃO' },
          ]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Button label="Confirmar" style="width: 100%" @click="confirmar" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import Config, { formatData, formatMoeda, hoje } from "@/config.ts";

export default {
  props: ["sol_id"],
  name: "solicitacoes-encaminhar",
  data() {
    return {
      funcionarios: "",
      funcSelecionado: "",
      func_id: "",
      nomefunc: "",
      overlay: false,
      natureza: "DEFERIMENTO",
      just: "",
    };
  },
  methods: {
    pesqfunc() {
      this.overlay = true;
      http
        .send("", {
          proc: `[FX jaguar Funcionarios] 'listagemUsuarios','${this.nomefunc}',${this.$store.state.contexto.FILIAL[0].e_id}`,
        })
        .then((res) => {
          console.log(res.dados);
          this.overlay = false;
          this.funcionarios = res.dados.data;
          this.funcSelecionado = this.funcionarios[0];
        });
    },
    confirmar() {
      this.func_id = this.funcSelecionado.usr_id;
      console.log("sol_id", this.sol_id);
      http
        .send("", {
          proc: `[FX jaguar alunos] 'encaminarSOL',${this.sol_id},${this.$store.state.contexto.BD[0].usr_id},${this.func_id},null,'${this.natureza}','${this.just}'`,
        })
        .then((res) => {
          this.$toast.success("Processamento realizado com sucesso");
          this.$emit("fechar_encaminhar");
        });
    },
  },
};
</script>

<style></style>
