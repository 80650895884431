<template>
  <div>
    <v-card class="mx-auto" max-width="600">
      <v-system-bar color="#283593" dark style="padding-top: 20px; padding-bottom: 20px">
        <span style="font-size: 15px">Perfil do usuário</span>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('sair')" style="margin-left: 10px">fal fa-times-circle</v-icon>
      </v-system-bar>
      <v-tabs fixed-tabs light>
        <v-tab style="texte-align: left">
          <v-icon left>mdi-account-key</v-icon>
          Trocar senha
        </v-tab>

        <v-tab-item>
          <v-card class="centralizar">
            <v-card-text>
              <v-row dense style="margin-top: 10px">
                <v-col>
                  <v-text-field
                    style="font-size=13px"
                    placeholder="Senha atual"
                    label="Senha atual"
                    :append-icon="mostrarsenhaatual ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="mostrarsenhaatual = !mostrarsenhaatual"
                    :type="mostrarsenhaatual ? 'text' : 'password'"
                    v-model="senhaatual"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense style="margin-top: -20px">
                <v-col>
                  <v-text-field
                    style="font-size=13px"
                    placeholder="Nova senha"
                    label="Nova senha"
                    dense
                    outlined
                    :append-icon="mostrarsenhanova ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="mostrarsenhanova = !mostrarsenhanova"
                    :type="mostrarsenhanova ? 'text' : 'password'"
                    v-model="senhanova"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense style="margin-top: -20px">
                <v-col>
                  <v-text-field
                    style="font-size=13px"
                    placeholder="Nova senha - confirmação"
                    label="Nova senha - confirmação"
                    dense
                    outlined
                    :append-icon="mostrarsenhanovaconfirma ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="mostrarsenhanovaconfirma = !mostrarsenhanovaconfirma"
                    :type="mostrarsenhanovaconfirma ? 'text' : 'password'"
                    v-model="senhanovaconfirma"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense style="margin-top: -10px">
                <v-col>
                  <Button style="width: 100%" class="p-button-raised" label="Confirmar" @click="confirmar"></Button>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-text-field label="Email" dense outlined></v-text-field>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import capturawebcam from "@/components/capturawebcam.vue";
export default {
  components: { capturawebcam },
  name: "userprofile",
  data() {
    return {
      mostrarsenhaatual: false,
      mostrarsenhanova: false,
      mostrarsenhanovaconfirma: false,
      senhanova: "",
      senhaatual: "",
      senhanovaconfirma: "",
    };
  },
  methods: {
    confirmar() {
      var senha = "";
      var codigo = "";
      if (this.$store.state.contexto.perfilgeral === "Aluno") {
        senha = this.$store.state.contexto.aluno.a_senhaweb;
        codigo = this.$store.state.contexto.aluno.a_id;
      } else {
        senha = this.$store.state.contexto.BD[0].usr_senha;
        codigo = this.$store.state.contexto.BD[0].usr_id;
      }

      if (senha !== this.senhaatual) {
        this.$toast.error("Senha atual não confere", "ERRO", { position: "center" });
      } else {
        if (this.senhanova !== this.senhanovaconfirma) {
          this.$toast.error("Senha nova e confirmação de senha nova são diferentes", "ERRO", { position: "center" });
        } else {
          http
            .send("", {
              proc: `[fx jaguar profile] 'trocarsenha','${this.$store.state.contexto.perfilgeral}','${codigo}','${this.senhanova}'`,
            })
            .then((res) => {
              this.$toast.success("Sua senha foi atualizada com sucesso", "SUCESSO", { position: "center" });
              this.$emit("sair");
            });
        }
      }
      console.log(`atual ${this.senhaatual}`);
      console.log(`nova ${this.senhanova}`);
      console.log(`nova conf ${this.senhanovaconfirma}`);
    },
  },
};
</script>

<style></style>
