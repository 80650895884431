const cadgrupos_usuarios = () => import("@/views/modulos/gestao/cadgrupos_usuarios.vue");
const cadusuariomensagens = () => import("@/views/modulos/gestao/cadusuariomensagens.vue");
const cadtabsiga = () => import("@/views/modulos/gestao/cadtabsiga.vue");
const cadtreinamentosProdutos = () => import('@/views/modulos/gestao/cadtreinamentos-produtos')
const treinamentos            = () => import('@/views/modulos/gestao/treinamentos')
const cadtiposavaliacao            = () => import('@/views/modulos/gestao/cadtiposavaliacao')
const cadavaliacaoprofessoritem = () => import("@/views/modulos/gestao/cadavaliacaoprofessoritem")
const cadfuncoes_sistema = () => import("@/views/modulos/gestao/cadfuncoes_sistema")
const cadcampos = () => import("@/views/modulos/gestao/cadcampos")
const cadgerenciadorarquivos = () => import("@/views/modulos/gestao/cadgerenciadorarquivos")
const gerenciadorArquivos = () => import("@/views/modulos/gestao/gerenciador-arquivos")

const portalalunoDocsGrupos = () => import("@/views/modulos/gestao/portalaluno-docs-grupos")
const configprocessos = () => import("@/views/modulos/gestao/config-processos")
const caddemandas = () => import("@/views/modulos/gestao/caddemandas")
const caddemantasprioridade = () => import("@/views/modulos/gestao/caddemantasprioridade")

export default [
  {
    path: "/caddemantasprioridade/:inc:mod:exc:codsis",
    name: "caddemantasprioridade",
    props: true,
    component: caddemantasprioridade,
  },

  {
    path: "/caddemandas/:inc:mod:exc:codsis",
    name: "caddemandas",
    props: true,
    component: caddemandas,
  },

  {
    path: "/portalaluno-docs-grupos/:inc:mod:exc:codsis",
    name: "portalaluno-docs-grupos",
    props: true,
    component: portalalunoDocsGrupos,
  },
  {
    path: "/config-processos/:inc:mod:exc:codsis",
    name: "config-processos",
    props: true,
    component: configprocessos,
  },
  {
    path: "/treinamentos",
    name: "treinamentos",
    props: true,
    component: treinamentos,
  },

    {
    path: "/gerenciador-arquivos",
    name: "gerenciador-arquivos",
    props: true,
    component: gerenciadorArquivos,
  },

  {
    path: "/cadgerenciadorarquivos/:inc:mod:exc:codsis",
    name: "cadgerenciadorarquivos",
    props: true,
    component: cadgerenciadorarquivos,
  },

  {
    path: "/cadavaliacaoprofessoritem/:inc:mod:exc:codsis",
    name: "cadavaliacaoprofessoritem",
    props: true,
    component: cadavaliacaoprofessoritem,
  },

  {
    path: "/cadcampos/:inc:mod:exc:codsis",
    name: "cadcampos",
    props: true,
    component: cadcampos,
  },

  {
    path: "/cadfuncoes_sistema/:inc:mod:exc:codsis",
    name: "cadfuncoes_sistema",
    props: true,
    component: cadfuncoes_sistema,
  },

  {
    path: "/cadtiposavaliacao/:inc:mod:exc:codsis",
    name: "cadtiposavaliacao",
    props: true,
    component: cadtiposavaliacao,
  },

  {
    path: "/cadtreinamentos-produtos/:inc:mod:exc:codsis",
    name: "cadtreinamentos-produtos",
    props: true,
    component: cadtreinamentosProdutos,
  },
  
  {
    path: "/cadgrupos_usuarios/:inc:mod:exc:codsis",
    name: "cadgrupos_usuarios",
    props: true,
    component: cadgrupos_usuarios,
  },

  {
    path: "/cadusuariomensagens/:inc:mod:exc:codsis",
    name: "cadusuariomensagens",
    props: true,
    component: cadusuariomensagens,
  },

  {
    path: "/cadtabsiga/:inc:mod:exc:codsis",
    name: "cadtabsiga",
    props: true,
    component: cadtabsiga,
  },
  
]