import store from "../store/index";

import { baseApiRel, baseApiRelXLS, baseApiRelPDF, httpRel } from "../config";

// import store from "@/store";

export default {
  async imprimirPDF(relatorio: any, params: any) {
    var linha = "";
    var d = "";

    if (params !== undefined) {
      for (var prop in params) {
        d = params[prop];
        linha = linha + (linha === "" ? "" : "&") + prop + "=" + d; // params[prop];
      }
    }

    var url = `${baseApiRelPDF.replace(
      "bco_nome",
      (store.state.contexto as any).BD[0].bco_nome
    )}${relatorio}&${linha}`;

    console.log(' PDF ' + url);
    /*
    var dados = await httpRel.request({
      url: url,
      method: "get"
    });
*/
     console.log('url do relatrio',url);
    return url; // dados;
    //    console.log(dados.data);
    //window.open(url, "_blank");

  },

  async imprimirxls(relatorio, params) {
    var linha = "";
    var d = "";

    if (params !== undefined) {
      for (var prop in params) {
        d = params[prop];
        linha = linha + (linha === "" ? "" : "&") + prop + "=" + d; // params[prop];
      }
    }

    var url = `${baseApiRelXLS}${relatorio}&${linha}`;

    console.log(url);
    /*
    var dados = await httpRel.request({
      url: url,
      method: "get"
    });
*/
    // console.log(url);
    return url; // dados;
    //    console.log(dados.data);
    //    window.open(url, "_self");
  },

  async imprimir(relatorio: any, params: any) {
    var linha = "";
    var d = "";

    if (params !== undefined) {
      for (var prop in params) {
        d = params[prop];
        linha = linha + (linha === "" ? "" : "&") + prop + "=" + d; // params[prop];
      }
    }

    var url = ""
    url = `${baseApiRel.replace(
      "bco_nome",
      (store.state.contexto as any).BD ? (store.state.contexto as any).BD[0].bco_nome : params.banco
    )}${relatorio}&${linha}`;

    console.log(url);
    /*
    var dados = await httpRel.request({
      url: url,
      method: "get"
    });
*/
    console.log("url do relatorio",url);
    return url; // dados;
    //    console.log(dados.data);
    //window.open(url, "_blank");
  },
};
