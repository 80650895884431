<template>
  <div style="color: white" v-if="$store.state.contexto">
    <v-row style="margin-top: -10px; margin-bottom: -12px">
      <v-col cols="9">
        <div><vue-file-toolbar-menu :content="myMenu" style="font-size: 14px; font-weight: 650; font-family:'Calibri'" /></div>
        <!--
        <div>
          <Menubar :model="myMenu" style="height: 40px;font-size: 14px; font-weight: 650; font-family:'Calibri'"> </Menubar>
        </div>
        -->
      </v-col>
      <v-col cols="3" style="text-align: right; margin-top: 5px; mergin-right: 5px; border-left: 1px solid #ccc; margin-left: -7px; height: 42px">
        <span style="font-size: 14px;font-family:'Roboto'; color: #000; padding-right: 10px;"
          ><b>{{ $store.state.contexto.titulo }}</b></span
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>

<script>
import VueFileToolbarMenu from "vue-file-toolbar-menu";
import { mapState } from "vuex";

export default {
  name: "Menu",
  components: { VueFileToolbarMenu },

  data() {
    return {
      happy: false,

      items: [
        {
          label: "File",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "New",
              icon: "pi pi-fw pi-plus",
              items: [
                {
                  label: "Bookmark",
                  icon: "pi pi-fw pi-bookmark",
                },
                {
                  label: "Video",
                  icon: "pi pi-fw pi-video",
                },
              ],
            },
            {
              label: "Delete",
              icon: "pi pi-fw pi-trash",
            },
            {
              separator: true,
            },
            {
              label: "Export",
              icon: "pi pi-fw pi-external-link",
            },
          ],
        },
        {
          label: "Edit",
          icon: "pi pi-fw pi-pencil",
          items: [
            {
              label: "Left",
              icon: "pi pi-fw pi-align-left",
            },
            {
              label: "Right",
              icon: "pi pi-fw pi-align-right",
            },
            {
              label: "Center",
              icon: "pi pi-fw pi-align-center",
            },
            {
              label: "Justify",
              icon: "pi pi-fw pi-align-justify",
            },
          ],
        },
        {
          label: "Users",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "New",
              icon: "pi pi-fw pi-user-plus",
            },
            {
              label: "Delete",
              icon: "pi pi-fw pi-user-minus",
            },
            {
              label: "Search",
              icon: "pi pi-fw pi-users",
              items: [
                {
                  label: "Filter",
                  icon: "pi pi-fw pi-filter",
                  items: [
                    {
                      label: "Print",
                      icon: "pi pi-fw pi-print",
                    },
                  ],
                },
                {
                  icon: "pi pi-fw pi-bars",
                  label: "List",
                },
              ],
            },
          ],
        },
        {
          label: "Events",
          icon: "pi pi-fw pi-calendar",
          items: [
            {
              label: "Edit",
              icon: "pi pi-fw pi-pencil",
              items: [
                {
                  label: "Save",
                  icon: "pi pi-fw pi-calendar-plus",
                },
                {
                  label: "Delete",
                  icon: "pi pi-fw pi-calendar-minus",
                },
              ],
            },
            {
              label: "Archieve",
              icon: "pi pi-fw pi-calendar-times",
              items: [
                {
                  label: "Remove",
                  icon: "pi pi-fw pi-calendar-minus",
                },
              ],
            },
          ],
        },
        {
          label: "Quit",
          icon: "pi pi-fw pi-power-off",
        },
      ],
    };
  },

  computed: {
    myMenu() {
      return this.contexto.menu;
    },
    ...mapState(["contexto"]),
  },
};
</script>

<style>
::v-deep.bar {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: var(--bar-font-color, rgba(0, 0, 0, 0.7));
  font-family: var(--bar-font-family, Avenir, Helvetica, Arial, sans-serif);
  font-size: var(--bar-font-size, 16px);
  font-weight: var(--bar-font-weight, 500);
  font-style: var(--bar-font-style);
  letter-spacing: var(--bar-letter-spacing);
  margin: var(--bar-margin);
  padding: var(--bar-padding);
  border: var(--bar-border);
  border-radius: var(--bar-border-radius);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  cursor: default;

  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border: 5px solid transparent;
    border-radius: 16px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bar-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--bar-button-padding, 7px);
    border-radius: var(--bar-button-radius, 3px);
    white-space: nowrap;

    &.active {
      color: var(--bar-button-active-color, rgb(65, 184, 131));
      background: var(--bar-button-active-bkg, rgb(234, 247, 244));
    }
    &.open:hover {
      color: var(--bar-button-open-color, rgb(65, 184, 131));
      background: var(--bar-button-open-bkg, rgb(234, 247, 244));
    }
    &.disabled {
      color: var(--bar-button-disabled-color, rgba(0, 0, 0, 0.3));
      background: var(--bar-button-disabled-bkg);
    }
    &:not(.active):not(.open):not(.disabled):hover {
      color: var(--bar-button-hover-color);
      background: var(--bar-button-hover-bkg, rgb(241, 243, 244));
    }
    & > .label {
      display: flex;
      align-items: center;
      padding: var(--bar-button-label-padding, 0 3px);
    }
    & > .icon,
    > .emoji {
      font-display: block;
      width: 1em;
      font-size: var(--bar-button-icon-size, 24px);
      margin: var(--bar-button-icon-margin);
    }
    & > .chevron {
      font-display: block;
      width: 1em;
      margin: var(--bar-button-chevron-margin, 0 -5px 0 0);
    }
    & > .menu {
      position: absolute;
      left: 0;
      top: 100%;
      display: none;
      z-index: 1;
    }
    & > .menu.align-left {
      left: 0;
    }
    & > .menu.align-center {
      left: auto;
    }
    & > .menu.align-right {
      left: auto;
      right: 0;
    }
    &.open:hover > .menu {
      display: block;
    }
  }

  .bar-menu {
    position: relative;
    white-space: normal;

    & > .extended-hover-zone {
      position: absolute;
      top: 0;
      left: -100px;
      right: -100px;
      bottom: -40px;
    }

    & > .bar-menu-items {
      position: relative;
      min-width: var(--bar-menu-min-width, 160px);
      color: var(--bar-menu-color, rgba(0, 0, 0, 0.7));
      background: var(--bar-menu-bkg, white);
      padding: var(--bar-menu-padding, 5px 0);
      box-shadow: var(--bar-menu-shadow, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px);
      border: var(--bar-menu-border);
      border-radius: var(--bar-menu-border-radius);

      @supports (backdrop-filter: var(--bar-menu-backdrop-filter)) {
        & {
          backdrop-filter: var(--bar-menu-backdrop-filter);
          background: var(--bar-menu-backdrop-filter-bkg, var(--bar-menu-bkg, white));
        }
      }

      & > .bar-menu-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: var(--bar-menu-item-font-size);
        padding: var(--bar-menu-item-padding, 8px 15px);

        &.active {
          color: var(--bar-menu-item-active-color);
          background: var(--bar-menu-item-active-bkg, rgb(231, 232, 233));
        }
        &.disabled {
          color: var(--bar-menu-item-disabled-color, rgba(0, 0, 0, 0.3));
        }
        &:not(.active):not(.disabled):hover {
          color: var(--bar-menu-item-hover-color);
          background: var(--bar-menu-item-hover-bkg, rgb(241, 243, 244));
        }
        & > .label {
          display: flex;
          align-items: center;
          flex-grow: 1;
        }
        & > .icon,
        > .emoji {
          font-display: block;
          width: 1em;
          font-size: var(--bar-menu-item-icon-size, 24px);
          margin: var(--bar-menu-item-icon-margin, 0 10px 0 0);
        }
        & > .chevron {
          font-display: block;
          width: 1em;
          margin: var(--bar-menu-item-chevron-margin, 0 -6px 0 0);
        }
        & > .menu {
          position: absolute;
          left: 100%;
          top: 0;
          display: none;
          z-index: 1;
        }
        &:hover > .menu {
          display: block;
        }
        & > .bar-menu {
          border-radius: var(--bar-sub-menu-border-radius);
        }
        & > .bar-menu > .extended-hover-zone {
          top: -100px;
          left: 0;
          bottom: -100px;
        }
      }
    }
  }
}
</style>
