<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"><span style="font-size: 12px">Aguarde</span></v-progress-circular>
    </v-overlay>

    <v-row dense>
      <v-col>
        <v-select
          @change="monta_texto"
          outlined
          dense
          hide-details
          label="Eventos"
          :items="registros"
          item-text="ea_descricao"
          item-value="eat_id"
          v-model="eat_id"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea outlined dense hide-details label="Complemento" v-model="just"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Button label="Confirmar" @click="confirmar"></Button>
        <Button label="Cancelar" @click="cancelar" style="margin-left: 10px"></Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";

export default {
  name: "solicitacoes-eventos",
  props: ["aluno", "serv_id"],
  data() {
    return {
      overlay: false,
      registros: "",
      eat_id: "",
      just: "",
    };
  },
  created() {
    this.overlay = true;
    http.send("", { proc: "[fx jaguar listas]", 1: "listaeventossolicitacoes" }).then((res) => {
      if (res.dados.data[0].eat_id) {
        this.registros = res.dados.data;
      } else {
        this.registros = [{ eat_id: "-1", ea_descricao: "Sem evento disponível" }];
      }
      this.eat_id = this.registros[0].eat_id;
      this.overlay = false;
      this.monta_texto(this.eat_id);
    });
  },
  methods: {
    monta_texto(event) {
      this.eat_id = event;
      this.just = "Aluno solicita inscrição no evento: " + this.registros.filter((r) => r.eat_id === this.eat_id)[0].ea_descricao;
    },
    cancelar() {
      this.$emit("cancelar");
    },
    confirmar() {
      http
        .send("", {
          proc: "[FX jaguar solicitacoes]",
          1: "eventos",
          2: this.serv_id,
          3: this.$store.state.contexto.BD[0].usr_id,
          4: this.eat_id,
          5: "",
          6: "",
          7: this.just,
          8: "0",
          9: this.aluno.cga,
        })
        .then((res) => {
          this.$toast.success("Solicitação incluida com sucesso!");
          this.$emit("confirmar");
        });
    },
  },
};
</script>

<style></style>
