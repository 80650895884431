<template>
  <div style="margin-bottom: 5px">
    <!--
    <v-system-bar color="#01579B" dark style="padding: 3px">
      <v-spacer></v-spacer>

      <v-icon @click="voltar" style="margin-left: 10px; font-weight: 350; font-size: 18px; color: white">fal fa-times-circle</v-icon>
    </v-system-bar>
-->
    <!--
    <v-app-bar dark color="blue darken-2" dense>

-->
    <div
      dark
      style="display: flex; height: 45px; background: #3f51b5"
      :color="$store.state.temas.backgroundWindow"
      dense
    >
      <div style="color: white; font-family: 'Roboto'; padding-left: 5px">
        <div
          v-html="titulo"
          style="height: 45px; position: relative; top: 25%; font-size: 18px"
        />
      </div>
      <v-spacer></v-spacer>
      <Button
        v-if="show_fechar === undefined || show_fechar === true"
        @click="voltar"
        style="
          border-left: 1px solid #ccc;
          border-radius: 0px;
          font-family: 'Roboto';
          margin-left: 10px;
          font-weight: 350;
          font-size: 15px;
          color: white;
        "
        icon="fal fa-times-circle"
        label="Fechar"
      />
    </div>
  </div>
  <!--
  <div style="padding: 3px;">    
    <v-system-bar color="#01579B" dark style="border-radius: 5px; padding-top: 20px; padding-bottom: 20px; font-size: 12px">
      <span style="color: white; font-size: 15px; font-weight: 500; font-family: 'Roboto'"><div v-html="titulo"/></span>
      <v-spacer></v-spacer>
      <v-icon @click="voltar" style="margin-left: 10px; font-weight: 350; font-size: 18px; color: white">fal fa-times-circle</v-icon>
    </v-system-bar>
  </div>
  -->
</template>

<script>
export default {
  name: "sysbar",
  props: ["titulo", "fechar", "show_fechar"],
  methods: {
    voltar() {
      this.$emit("fechar");
    },
  },
};
</script>

<style></style>
