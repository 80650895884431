<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"
        ><span style="font-size: 12px">Aguarde</span></v-progress-circular
      >
    </v-overlay>

    <v-row dense style="margin-top: -20px">
      <v-col>
        <v-select
          outlined
          dense
          hide-details
          label="Tipo de atividade"
          v-model="tac_id"
          :items="registros"
          item-text="tac_descricao"
          item-value="tac_id"
          @change="listadocs"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="titulo"
          label="Título da atividade"
        />
      </v-col>
      <v-col>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="pessoa"
          label="Pessoa jurídica responsável pela certificação"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-text-field
          outlined
          dense
          hide-details
          v-model="data"
          label="Data inicial"
          v-mask="'##/##/####'"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          outlined
          dense
          hide-details
          v-model="datafinal"
          label="Data final"
          v-mask="'##/##/####'"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          outlined
          dense
          hide-details
          v-model="ch"
          label="Carga horária"
          v-mask="'###'"
        />
      </v-col>
      <v-col cols="8">
        <v-textarea
          outlined
          dense
          hide-details
          v-model="just"
          label="Complemento"
          rows="1"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <table
          style="border: 1px solid #ccc; width: 100%; border-collapse: collapse"
          border="1"
        >
          <thead>
            <tr>
              <th style="background-color: #ddd; padding: 5px" width="300px">
                Documento
              </th>
              <th style="background-color: #ddd; padding: 5px" width="250px">
                Arquivo
              </th>
              <th
                style="background-color: #ddd; padding: 5px"
                width="50px"
              ></th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, idx) in tipoacdoc"
              :key="idx"
              :class="item.tad_id === tad_id ? 'marcar' : null"
            >
              <td>
                <div
                  :title="item.tad_descricao"
                  style="
                    padding: 5px;
                    text-overflow: ellipsis;
                    width: 300px;
                    overflow: hidden;
                    white-space: nowrap;
                  "
                >
                  {{ item.tad_descricao }}
                </div>
              </td>
              <td style="padding: 5px">
                {{
                  arquivos[item.tad_id]
                    ? arquivos[item.tad_id].arquivo.name
                    : ""
                }}
              </td>

              <td style="text-align: center">
                <v-icon
                  title="Upload doc"
                  @click="
                    tad_id = item.tad_id;
                    incfile = true;
                  "
                  >mdi mdi-scanner</v-icon
                >
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Button label="Confirmar" @click="confirmar"></Button>
        <Button
          label="Cancelar"
          @click="cancelar"
          style="margin-left: 10px"
        ></Button>
      </v-col>
    </v-row>

    <v-dialog v-model="incfile" width="500">
      <v-card>
        <sysbar titulo="Upload" @fechar="incfile = false"></sysbar>
        <v-card-text>
          <v-row dense style="margin-top: 10px">
            <v-col>
              <v-file-input
                v-model="arquivo"
                outlined
                dense
                label="Arquivos"
                single
                prepend-icon="mdi-paperclip"
              >
              </v-file-input>
            </v-col>
          </v-row>
          <v-row dense style="margin-top: -20px">
            <v-col>
              <Button
                style="width: 100%"
                label="Confirmar"
                @click="upload"
              ></Button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import sysbar from "@/components/sysbar";
import Config, { formatData, formatMoeda, hoje } from "@/config.ts";
import axios from "axios";

export default {
  name: "solicitacoes-ativcompl",
  props: ["serv_id", "m_id", "aluno"],
  components: { sysbar },
  data() {
    return {
      just: "Aluno solicita registro de atividade complementar conforme docs anexo",
      tac_id: "",
      registro: "",
      registros: "",
      tipoacdoc: "",
      titulo: "",
      pessoa: "",
      data: "",
      datafinal: "",
      ch: "",
      overlay: false,
      arquivo: "",
      tad_id: "",
      incfile: false,
      arquivos: [],
      sol_id: "",
    };
  },
  created() {
    this.overlay = true;
    http
      .send("", { proc: "[fx jaguar listas]", 1: "listatipoativcompl" })
      .then((res) => {
        this.registros = res.dados.data;
        this.tac_id = res.dados.data[0].tac_id;
        this.overlay = false;
        this.listadocs(this.tac_id);
      });
  },
  methods: {
    upload() {
      this.arquivos[this.tad_id] = { arquivo: this.arquivo };
      this.incfile = false;
      this.arquivo = "";
    },
    listadocs(item) {
      http
        .send("", { proc: "[fx jaguar listas]", 1: "listadocstac", 2: item })
        .then((res) => {
          if (res.dados.data[0].tad_descricao) {
            this.tipoacdoc = res.dados.data;
          } else this.tipoacdoc = "";
        });
    },
    cancelar() {
      this.$emit("cancelar");
    },
    confirmar() {
      http
        .send("", {
          proc: "[FX jaguar solicitacoes]",
          1: "ativcompl",
          2: this.serv_id,
          3: this.$store.state.contexto.BD[0].usr_id,
          4: this.tac_id,
          5: this.titulo,
          6: this.pessoa,
          7: this.data,
          8: "0",
          9: this.aluno.cga,
          10: this.ch,
          11: this.datafinal,
        })
        .then((res) => {
          this.sol_id = res.dados.data[0].sol_id;
          for (let index = 0; index < this.tipoacdoc.length; index++) {
            this.execupload(
              this.arquivos[this.tipoacdoc[index].tad_id].arquivo
            );
          }
          this.overlay = false;
          this.$emit("confirmar");
        });
    },

    async execupload(file) {
      await http
        .send("", {
          proc: "[fx jaguar listas]",
          1: "incanexosol",
          2: this.sol_id,
          3: file.name,
        })
        .then((res) => {
          const formData = new FormData();
          const url =
            Config.baseApiUrlUpload +
            `/upload?destino=${this.$store.state.contexto.BD[0].bco_pasta.trim()}/alunos/${
              this.aluno.cga
                .replace(".", "")
                .replace(".", "")
                .replace("-", "")
                .trim() + "/solicitacoes/"
            }&codigo=${this.sol_id}`;

          formData.append("file", file);

          axios.post(url, formData).then((res) => {
            this.$toast.success("uploaded " + file.name);
          });
        });
    },
  },
};
</script>

<style scope>
.marcar {
  background-color: #ccc;
  font-weight: 650;
}
</style>
