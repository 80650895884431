const portalaluno = () => import("@/views/modulos/portais/aluno/portalaluno.vue");
const cadgrupo = () => import("@/views/modulos/portais/aluno/caddashboard-grupo.vue");
const caddashboard = () => import("@/views/modulos/portais/aluno/caddashboard.vue");
  
export default [
  {
    path: "/portalaluno/:inc:mod:exc:codsis",
    name: "portalaluno",
    props: true,
    component: portalaluno,
  },

   {
    path: "/caddashboard-grupo/:inc:mod:exc:codsis",
    name: "cadgrupo",
    props: true,
    component: cadgrupo,
  },

    {
    path: "/caddashboard/:inc:mod:exc:codsis",
    name: "caddashboard",
    props: true,
    component: caddashboard,
  },
  
];
